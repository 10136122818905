/* 1. BadScript-Regular */
@font-face {
  font-family: 'BadScript';
  src: url('../public/fonts/woff2/BadScript-Regular.woff2') format('woff2'),
    url('../public/fonts/woff/BadScript-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* 2. BonaNova-Bold */
@font-face {
  font-family: 'BonaNova';
  src: url('../public/fonts/woff2/BonaNova-Bold.woff2') format('woff2'),
    url('../public/fonts/woff/BonaNova-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

/* 3. BonaNova-Regular */
@font-face {
  font-family: 'BonaNova';
  src: url('../public/fonts/woff2/BonaNova-Regular.woff2') format('woff2'),
    url('../public/fonts/woff/BonaNova-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* 4. Caveat-Bold */
@font-face {
  font-family: 'Caveat';
  src: url('../public/fonts/woff2/Caveat-Bold.woff2') format('woff2'),
    url('../public/fonts/woff/Caveat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

/* 5. Caveat-Regular */
@font-face {
  font-family: 'Caveat';
  src: url('../public/fonts/woff2/Caveat-Regular.woff2') format('woff2'),
    url('../public/fonts/woff/Caveat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* 6. Inter-Black */
@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/woff2/Inter-Black.woff2') format('woff2'),
    url('../public/fonts/woff/Inter-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

/* 7. Inter-Bold */
@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/woff2/Inter-Bold.woff2') format('woff2'),
    url('../public/fonts/woff/Inter-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

/* 8. Inter-Light */
@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/woff2/Inter-Light.woff2') format('woff2'),
    url('../public/fonts/woff/Inter-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

/* 9. Inter-Medium */
@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/woff2/Inter-Medium.woff2') format('woff2'),
    url('../public/fonts/woff/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

/* 10. Inter-Regular */
@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/woff2/Inter-Regular.woff2') format('woff2'),
    url('../public/fonts/woff/Inter-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

/* 11. Inter-Thin */
@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/woff2/Inter-Thin.woff2') format('woff2'),
    url('../public/fonts/woff/Inter-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

/* 12. Jost-Black */
@font-face {
  font-family: 'Jost';
  src: url('../public/fonts/woff2/Jost-Black.woff2') format('woff2'),
    url('../public/fonts/woff/Jost-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

/* 13. Jost-Bold */
@font-face {
  font-family: 'Jost';
  src: url('../public/fonts/woff2/Jost-Bold.woff2') format('woff2'),
    url('../public/fonts/woff/Jost-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

/* 14. Jost-Light */
@font-face {
  font-family: 'Jost';
  src: url('../public/fonts/woff2/Jost-Light.woff2') format('woff2'),
    url('../public/fonts/woff/Jost-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

/* 15. Jost-Medium */
@font-face {
  font-family: 'Jost';
  src: url('../public/fonts/woff2/Jost-Medium.woff2') format('woff2'),
    url('../public/fonts/woff/Jost-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

/* 16. Jost-Regular */
@font-face {
  font-family: 'Jost';
  src: url('../public/fonts/woff2/Jost-Regular.woff2') format('woff2'),
    url('../public/fonts/woff/Jost-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* 17. Jost-Thin */
@font-face {
  font-family: 'Jost';
  src: url('../public/fonts/woff2/Jost-Thin.woff2') format('woff2'),
    url('../public/fonts/woff/Jost-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

/* 18. Jura-Bold */
@font-face {
  font-family: 'Jura';
  src: url('../public/fonts/woff2/Jura-Bold.woff2') format('woff2'),
    url('../public/fonts/woff/Jura-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

/* 19. Jura-Light */
@font-face {
  font-family: 'Jura';
  src: url('../public/fonts/woff2/Jura-Light.woff2') format('woff2'),
    url('../public/fonts/woff/Jura-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

/* 20. Jura-Medium */
@font-face {
  font-family: 'Jura';
  src: url('../public/fonts/woff2/Jura-Medium.woff2') format('woff2'),
    url('../public/fonts/woff/Jura-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

/* 21. Jura-Regular */
@font-face {
  font-family: 'Jura';
  src: url('../public/fonts/woff2/Jura-Regular.woff2') format('woff2'),
    url('../public/fonts/woff/Jura-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* 22. Lora-Bold */
@font-face {
  font-family: 'Lora';
  src: url('../public/fonts/woff2/Lora-Bold.woff2') format('woff2'),
    url('../public/fonts/woff/Lora-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

/* 23. Lora-Medium */
@font-face {
  font-family: 'Lora';
  src: url('../public/fonts/woff2/Lora-Medium.woff2') format('woff2'),
    url('../public/fonts/woff/Lora-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

/* 24. Lora-Regular */
@font-face {
  font-family: 'Lora';
  src: url('../public/fonts/woff2/Lora-Regular.woff2') format('woff2'),
    url('../public/fonts/woff/Lora-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* 25. Merriweather-Black */
@font-face {
  font-family: 'Merriweather';
  src: url('../public/fonts/woff2/Merriweather-Black.woff2') format('woff2'),
    url('../public/fonts/woff/Merriweather-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

/* 26. Merriweather-Bold */
@font-face {
  font-family: 'Merriweather';
  src: url('../public/fonts/woff2/Merriweather-Bold.woff2') format('woff2'),
    url('../public/fonts/woff/Merriweather-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

/* 27. Merriweather-Light */
@font-face {
  font-family: 'Merriweather';
  src: url('../public/fonts/woff2/Merriweather-Light.woff2') format('woff2'),
    url('../public/fonts/woff/Merriweather-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

/* 28. Merriweather-Regular */
@font-face {
  font-family: 'Merriweather';
  src: url('../public/fonts/woff2/Merriweather-Regular.woff2') format('woff2'),
    url('../public/fonts/woff/Merriweather-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* 29. Montserrat-Black */
@font-face {
  font-family: 'Montserrat';
  src: url('../public/fonts/woff2/Montserrat-Black.woff2') format('woff2'),
    url('../public/fonts/woff/Montserrat-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

/* 30. Montserrat-Bold */
@font-face {
  font-family: 'Montserrat';
  src: url('../public/fonts/woff2/Montserrat-Bold.woff2') format('woff2'),
    url('../public/fonts/woff/Montserrat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

/* 31. Montserrat-Light */
@font-face {
  font-family: 'Montserrat';
  src: url('../public/fonts/woff2/Montserrat-Light.woff2') format('woff2'),
    url('../public/fonts/woff/Montserrat-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

/* 32. Montserrat-Medium */
@font-face {
  font-family: 'Montserrat';
  src: url('../public/fonts/woff2/Montserrat-Medium.woff2') format('woff2'),
    url('../public/fonts/woff/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

/* 33. Montserrat-Regular */
@font-face {
  font-family: 'Montserrat';
  src: url('../public/fonts/woff2/Montserrat-Regular.woff2') format('woff2'),
    url('../public/fonts/woff/Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* 34. Montserrat-Thin */
@font-face {
  font-family: 'Montserrat';
  src: url('../public/fonts/woff2/Montserrat-Thin.woff2') format('woff2'),
    url('../public/fonts/woff/Montserrat-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

/* 35. Neucha-Regular */
@font-face {
  font-family: 'Neucha';
  src: url('../public/fonts/woff2/Neucha-Regular.woff2') format('woff2'),
    url('../public/fonts/woff/Neucha-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* 36. NotoSans-Black */
@font-face {
  font-family: 'NotoSans';
  src: url('../public/fonts/woff2/NotoSans-Black.woff2') format('woff2'),
    url('../public/fonts/woff/NotoSans-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

/* 37. NotoSans-Bold */
@font-face {
  font-family: 'NotoSans';
  src: url('../public/fonts/woff2/NotoSans-Bold.woff2') format('woff2'),
    url('../public/fonts/woff/NotoSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

/* 38. NotoSans-Light */
@font-face {
  font-family: 'NotoSans';
  src: url('../public/fonts/woff2/NotoSans-Light.woff2') format('woff2'),
    url('../public/fonts/woff/NotoSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

/* 39. NotoSans-Medium */
@font-face {
  font-family: 'NotoSans';
  src: url('../public/fonts/woff2/NotoSans-Medium.woff2') format('woff2'),
    url('../public/fonts/woff/NotoSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

/* 40. NotoSans-Regular */
@font-face {
  font-family: 'NotoSans';
  src: url('../public/fonts/woff2/NotoSans-Regular.woff2') format('woff2'),
    url('../public/fonts/woff/NotoSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* 41. NotoSans-Thin */
@font-face {
  font-family: 'NotoSans';
  src: url('../public/fonts/woff2/NotoSans-Thin.woff2') format('woff2'),
    url('../public/fonts/woff/NotoSans-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

/* 42. Nunito-Black */
@font-face {
  font-family: 'Nunito';
  src: url('../public/fonts/woff2/Nunito-Black.woff2') format('woff2'),
    url('../public/fonts/woff/Nunito-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

/* 43. Nunito-Bold */
@font-face {
  font-family: 'Nunito';
  src: url('../public/fonts/woff2/Nunito-Bold.woff2') format('woff2'),
    url('../public/fonts/woff/Nunito-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

/* 44. Nunito-Light */
@font-face {
  font-family: 'Nunito';
  src: url('../public/fonts/woff2/Nunito-Light.woff2') format('woff2'),
    url('../public/fonts/woff/Nunito-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

/* 45. Nunito-Medium */
@font-face {
  font-family: 'Nunito';
  src: url('../public/fonts/woff2/Nunito-Medium.woff2') format('woff2'),
    url('../public/fonts/woff/Nunito-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

/* 46. Nunito-Regular */
@font-face {
  font-family: 'Nunito';
  src: url('../public/fonts/woff2/Nunito-Regular.woff2') format('woff2'),
    url('../public/fonts/woff/Nunito-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* 47. Oranienbaum-Regular */
@font-face {
  font-family: 'Oranienbaum';
  src: url('../public/fonts/woff2/Oranienbaum-Regular.woff2') format('woff2'),
    url('../public/fonts/woff/Oranienbaum-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* 48. Oswald-Bold */
@font-face {
  font-family: 'Oswald';
  src: url('../public/fonts/woff2/Oswald-Bold.woff2') format('woff2'),
    url('../public/fonts/woff/Oswald-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

/* 49. Oswald-Light */
@font-face {
  font-family: 'Oswald';
  src: url('../public/fonts/woff2/Oswald-Light.woff2') format('woff2'),
    url('../public/fonts/woff/Oswald-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

/* 50. Oswald-Medium */
@font-face {
  font-family: 'Oswald';
  src: url('../public/fonts/woff2/Oswald-Medium.woff2') format('woff2'),
    url('../public/fonts/woff/Oswald-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

/* 51. Oswald-Regular */
@font-face {
  font-family: 'Oswald';
  src: url('../public/fonts/woff2/Oswald-Regular.woff2') format('woff2'),
    url('../public/fonts/woff/Oswald-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* 52. Philosopher-Bold */
@font-face {
  font-family: 'Philosopher';
  src: url('../public/fonts/woff2/Philosopher-Bold.woff2') format('woff2'),
    url('../public/fonts/woff/Philosopher-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

/* 53. Philosopher-Regular */
@font-face {
  font-family: 'Philosopher';
  src: url('../public/fonts/woff2/Philosopher-Regular.woff2') format('woff2'),
    url('../public/fonts/woff/Philosopher-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* 54. PlayfairDisplay-Black */
@font-face {
  font-family: 'PlayfairDisplay';
  src: url('../public/fonts/woff2/PlayfairDisplay-Black.woff2') format('woff2'),
    url('../public/fonts/woff/PlayfairDisplay-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

/* 55. PlayfairDisplay-Bold */
@font-face {
  font-family: 'PlayfairDisplay';
  src: url('../public/fonts/woff2/PlayfairDisplay-Bold.woff2') format('woff2'),
    url('../public/fonts/woff/PlayfairDisplay-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

/* 56. PlayfairDisplay-Medium */
@font-face {
  font-family: 'PlayfairDisplay';
  src: url('../public/fonts/woff2/PlayfairDisplay-Medium.woff2') format('woff2'),
    url('../public/fonts/woff/PlayfairDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

/* 57. PlayfairDisplay-Regular */
@font-face {
  font-family: 'PlayfairDisplay';
  src: url('../public/fonts/woff2/PlayfairDisplay-Regular.woff2') format('woff2'),
    url('../public/fonts/woff/PlayfairDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* 58. Prata-Regular */
@font-face {
  font-family: 'Prata';
  src: url('../public/fonts/woff2/Prata-Regular.woff2') format('woff2'),
    url('../public/fonts/woff/Prata-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* 59. Roboto-Black */
@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/woff2/Roboto-Black.woff2') format('woff2'),
    url('../public/fonts/woff/Roboto-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

/* 60. Roboto-Bold */
@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/woff2/Roboto-Bold.woff2') format('woff2'),
    url('../public/fonts/woff/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

/* 61. RobotoCondensed-Bold */
@font-face {
  font-family: 'RobotoCondensed';
  src: url('../public/fonts/woff2/RobotoCondensed-Bold.woff2') format('woff2'),
    url('../public/fonts/woff/RobotoCondensed-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

/* 62. RobotoCondensed-Light */
@font-face {
  font-family: 'RobotoCondensed';
  src: url('../public/fonts/woff2/RobotoCondensed-Light.woff2') format('woff2'),
    url('../public/fonts/woff/RobotoCondensed-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

/* 63. RobotoCondensed-Regular */
@font-face {
  font-family: 'RobotoCondensed';
  src: url('../public/fonts/woff2/RobotoCondensed-Regular.woff2') format('woff2'),
    url('../public/fonts/woff/RobotoCondensed-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* 64. Roboto-Light */
@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/woff2/Roboto-Light.woff2') format('woff2'),
    url('../public/fonts/woff/Roboto-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

/* 65. Roboto-Medium */
@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/woff2/Roboto-Medium.woff2') format('woff2'),
    url('../public/fonts/woff/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

/* 66. Roboto-Regular */
@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/woff2/Roboto-Regular.woff2') format('woff2'),
    url('../public/fonts/woff/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* 67. RobotoSlab-Black */
@font-face {
  font-family: 'RobotoSlab';
  src: url('../public/fonts/woff2/RobotoSlab-Black.woff2') format('woff2'),
    url('../public/fonts/woff/RobotoSlab-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

/* 68. RobotoSlab-Bold */
@font-face {
  font-family: 'RobotoSlab';
  src: url('../public/fonts/woff2/RobotoSlab-Bold.woff2') format('woff2'),
    url('../public/fonts/woff/RobotoSlab-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

/* 69. RobotoSlab-Light */
@font-face {
  font-family: 'RobotoSlab';
  src: url('../public/fonts/woff2/RobotoSlab-Light.woff2') format('woff2'),
    url('../public/fonts/woff/RobotoSlab-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

/* 70. RobotoSlab-Medium */
@font-face {
  font-family: 'RobotoSlab';
  src: url('../public/fonts/woff2/RobotoSlab-Medium.woff2') format('woff2'),
    url('../public/fonts/woff/RobotoSlab-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

/* 71. RobotoSlab-Regular */
@font-face {
  font-family: 'RobotoSlab';
  src: url('../public/fonts/woff2/RobotoSlab-Regular.woff2') format('woff2'),
    url('../public/fonts/woff/RobotoSlab-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* 72. RobotoSlab-Thin */
@font-face {
  font-family: 'RobotoSlab';
  src: url('../public/fonts/woff2/RobotoSlab-Thin.woff2') format('woff2'),
    url('../public/fonts/woff/RobotoSlab-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

/* 73. Roboto-Thin */
@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/woff2/Roboto-Thin.woff2') format('woff2'),
    url('../public/fonts/woff/Roboto-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

/* 74. TenorSans-Regular */
@font-face {
  font-family: 'TenorSans';
  src: url('../public/fonts/woff2/TenorSans-Regular.woff2') format('woff2'),
    url('../public/fonts/woff/TenorSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
