@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@500;600;700&display=swap');
@import './fonts.scss';

@font-face {
  font-family: 'icomoon';
  src: url('./assets/fonts/icomoon/icomoon.eot?ktl1jy');
  src: url('./assets/fonts/icomoon/icomoon.eot?ktl1jy#iefix') format('embedded-opentype'),
    url('./assets/fonts/icomoon/icomoon.ttf?ktl1jy') format('truetype'),
    url('./assets/fonts/icomoon/icomoon.woff?ktl1jy') format('woff'),
    url('./assets/fonts/icomoon/icomoon.svg?ktl1jy#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

// @import url('https://fonts.googleapis.com/css2?family=Bad+Script:wght@400&family=Bona+Nova:wght@400;700&family=Caveat:wght@400;700&family=Inter:wght@100;300;400;500;700;900&family=Jost:wght@100;300;400;500;700;900&family=Jura:wght@300;400;500;700&family=Lora:wght@400;500;700&family=Merriweather:wght@300;400;700;900&family=Montserrat:wght@100;300;400;500;700;900&family=Neucha:wght@400&family=Noto+Sans:wght@100;300;400;500;700;900&family=Nunito:wght@300;400;500;700;900&family=Oranienbaum:wght@400&family=Oswald:wght@300;400;500;700&family=Philosopher:wght@400;700&family=Playfair+Display:wght@400;500;700;900&family=Prata:wght@400&family=Roboto+Condensed:wght@300;400;700&family=Roboto+Slab:wght@100;300;400;500;700;900&family=Roboto:wght@100;300;400;500;700;900&family=Tenor+Sans:wght@400&display=swap');

*,
::before,
::after {
  box-sizing: border-box;
}

* {
  font-family: 'Manrope', sans-serif;
  font-weight: 600;
}

html {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), #ffffff;
}

body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  ::-webkit-scrollbar {
    height: 50%;
  }
}

textarea[data-fabric-hiddentextarea] {
  position: fixed !important;
}

[class^='icon-'],
[class*=' icon-'] {
  font-family: 'icomoon', serif !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[data-tooltip] {
  &:hover {
    position: relative;

    &::before {
      content: attr(data-tooltip);
      display: block;
      position: absolute;
      top: 50%;
      left: calc(100% + 5px);
      transform: translateY(-50%);
      color: #ffffff;
      z-index: 10000000;

      background-color: #000000;
      height: 31px;
      border-radius: 8px;
      padding: 0 10px;
      text-align: center;
      line-height: 31px;
      font-size: 14px;
      font-weight: 600;
      transition: all 0.3s ease-in;
      white-space: nowrap;
    }

    &[data-tooltip-position='left'] {
      &::before {
        left: auto;
        right: calc(100% + 5px);
      }
    }
    &[data-tooltip-position='top'] {
      &::before {
        top: auto;
        bottom: calc(100% + 5px);
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &[data-tooltip-position='bottom'] {
      &::before {
        top: calc(100% + 5px);
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

[data-tooltip-wrapped] {
  &:hover {
    position: relative;

    &::before {
      content: attr(data-tooltip-wrapped);
      display: block;
      position: absolute;
      top: 50%;
      left: calc(100% + 5px);
      transform: translateY(-50%);
      color: #ffffff;
      z-index: 10000000;
      background-color: #000000;
      border-radius: 8px;
      padding: 6px 10px;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      transition: all 0.3s ease-in;
      max-width: 150px;
      word-wrap: break-word;
      white-space: wrap;
    }

    &[data-tooltip-position='left-bottom'] {
      &::before {
        top: calc(100% + 5px);
        transform: translateX(-100%);
      }
    }
    &[data-tooltip-position='left-bottom-nowrap'] {
      &::before {
        top: calc(100% + 5px);
        transform: translateX(-100%);
        white-space: nowrap;
      }
    }
    &[data-tooltip-position='right-bottom'] {
      &::before {
        top: calc(100% + 5px);
        left: 0%;
        transform: translateX(0%);
      }
    }

    &[data-tooltip-position='left-top'] {
      &::before {
        top: auto;
        bottom: calc(100% + 5px);
        transform: translateX(-100%);
      }
    }
    &[data-tooltip-position='right-top'] {
      &::before {
        top: auto;
        bottom: calc(100% + 5px);
        left: 0%;
        transform: translateX(0%);
      }
    }

    &[data-tooltip-position='top'] {
      &::before {
        top: auto;
        bottom: calc(100% + 5px);
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &[data-tooltip-position='bottom'] {
      &::before {
        top: calc(100% + 5px);
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.df {
  display: flex;
}

.fw-w {
  flex-wrap: wrap;
}

.colorPopover {
  padding: 0.625rem 0.9375rem !important;
  width: 23.75rem !important;
  & .react-colorful {
    width: 100%;
    &__saturation {
      border-radius: 0;
      margin: 0 -0.9375rem;
      height: 10.3125rem;
    }
    &__pointer {
      width: 1rem;
      height: 1rem;
      &:hover {
        width: 1.25rem;
        height: 1.25rem;
        border: 4px solid #fff;
      }
    }
    &__alpha,
    &__hue {
      width: 88%;
      height: 1.375rem;
      border-radius: 8px;
      margin-top: 1rem;
      border: 1px solid #cccccc;

      & .react-colorful__interactive {
        width: 95%;
        left: 8px;
      }
    }

    &__alpha {
      background: url('./assets/images/checkered-background.svg');
    }

    &__alpha__text {
      position: absolute;
      top: -2px;
      left: calc(100% + 5px);
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.1875rem;
      color: #000000;
    }
  }
}

s {
  color: red;
  display: inline-block;
  text-decoration: none;
  position: relative;
}

s:after {
  position: absolute;
  left: 0;
  right: 0;
  content: ' ';
  background: #df3b2c;
  height: 0.125rem;
  transform: translateY(-50%);
  top: 50%;
}

.react-colorful__saturation {
  border-bottom: 0px !important;
}

// ICONS
.icon-pencil:before {
  content: '\e900';
}
.icon-question:before {
  content: '\e901';
}
.icon-watermark:before {
  content: '\e902';
}
.icon-underline:before {
  content: '\e903';
}
.icon-strikethrough:before {
  content: '\e904';
}
.icon-shadow:before {
  content: '\e905';
}
.icon-select-all:before {
  content: '\e906';
}
.icon-search:before {
  content: '\e907';
}
.icon-list:before {
  content: '\e908';
}
.icon-list-numbers:before {
  content: '\e909';
}
.icon-lines:before {
  content: '\e90a';
}
.icon-lines-style:before {
  content: '\e90b';
}
.icon-line-height:before {
  content: '\e90c';
}
.icon-light:before {
  content: '\e90d';
}
.icon-letter-spacing:before {
  content: '\e90e';
}
.icon-layers-front:before {
  content: '\e90f';
}
.icon-layers-forward:before {
  content: '\e910';
}
.icon-layers-backward:before {
  content: '\e911';
}
.icon-layers-back:before {
  content: '\e912';
}
.icon-italic:before {
  content: '\e913';
}
.icon-group:before {
  content: '\e914';
}
.icon-font:before {
  content: '\e915';
}
.icon-dotted:before {
  content: '\e916';
}
.icon-dashed:before {
  content: '\e917';
}
.icon-corners:before {
  content: '\e918';
}
.icon-circle:before {
  content: '\e919';
}
.icon-circle-slashed:before {
  content: '\e91a';
}
.icon-bold:before {
  content: '\e91b';
}
.icon-align-right:before {
  content: '\e91c';
}
.icon-align-left:before {
  content: '\e91d';
}
.icon-align-justify:before {
  content: '\e91e';
}
.icon-align-center:before {
  content: '\e91f';
}
.icon-wand:before {
  content: '\e920';
}
.icon-trash:before {
  content: '\e921';
}
.icon-timer:before {
  content: '\e922';
}
.icon-templates:before {
  content: '\e923';
}
.icon-square:before {
  content: '\e924';
}
.icon-select:before {
  content: '\e925';
}
.icon-rotate:before {
  content: '\e926';
}
.icon-reset:before {
  content: '\e927';
}
.icon-rectangle:before {
  content: '\e928';
}
.icon-pipette:before {
  content: '\e929';
}
.icon-past:before {
  content: '\e92a';
}
.icon-image-off:before {
  content: '\e92b';
}
.icon-folder:before {
  content: '\e92c';
}
.icon-folder-plus:before {
  content: '\e92d';
}
.icon-to-folder:before {
  content: '\e92e';
}
.icon-filter:before {
  content: '\e92f';
}
.icon-eye-on:before {
  content: '\e930';
}
.icon-eye-off:before {
  content: '\e931';
}
.icon-edit:before {
  content: '\e932';
}
.icon-double:before {
  content: '\e933';
}
.icon-crop:before {
  content: '\e934';
}
.icon-copy:before {
  content: '\e935';
}
.icon-congrats:before {
  content: '\e936';
}
.icon-card:before {
  content: '\e937';
}
.icon-align-start-vertical:before {
  content: '\e938';
}
.icon-align-start-horizontal:before {
  content: '\e939';
}
.icon-align-end-vertical:before {
  content: '\e93a';
}
.icon-align-end-horizontal:before {
  content: '\e93b';
}
.icon-align-center-vertical:before {
  content: '\e93c';
}
.icon-align-center-horizontal:before {
  content: '\e93d';
}
.icon-alert-circle:before {
  content: '\e93e';
}
.icon-more:before {
  content: '\e93f';
}
.icon-log-in:before {
  content: '\e940';
}
.icon-log-out:before {
  content: '\e941';
}
.icon-check:before {
  content: '\e942';
}
.icon-close:before {
  content: '\e943';
}
.icon-arrow-up:before {
  content: '\e944';
}
.icon-arrow-down:before {
  content: '\e945';
}
.icon-arrow-right:before {
  content: '\e946';
}
.icon-arrow-left:before {
  content: '\e947';
}
.icon-minus:before {
  content: '\e948';
}
.icon-scaling:before {
  content: '\e949';
}
.icon-components-plus:before {
  content: '\e94a';
}
.icon-components:before {
  content: '\e94b';
}
.icon-star-filled:before {
  content: '\e94c';
}
.icon-star:before {
  content: '\e94d';
}
.icon-triangle:before {
  content: '\e94e';
}
.icon-text-cursor:before {
  content: '\e94f';
}
.icon-image:before {
  content: '\e950';
}
.icon-image-plus:before {
  content: '\e951';
}
.icon-chevron-right:before {
  content: '\e952';
}
.icon-chevron-left:before {
  content: '\e953';
}
.icon-chevron-up:before {
  content: '\e954';
}
.icon-chevron-down:before {
  content: '\e955';
}
.icon-heart-filled:before {
  content: '\e956';
}
.icon-heart:before {
  content: '\e957';
}
.icon-redo:before {
  content: '\e958';
}
.icon-undo:before {
  content: '\e959';
}
.icon-upload:before {
  content: '\e95a';
}
.icon-download:before {
  content: '\e95b';
}
.icon-file-plus:before {
  content: '\e95c';
}
.icon-mail-question:before {
  content: '\e95d';
}
.icon-plus:before {
  content: '\e95e';
}
